<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      d="M17.707 8.293c-0.391-0.391-1.023-0.391-1.414 0s-0.391 1.023 0 1.414l1.293 1.293h-4.586v-4.586l1.293 1.293c0.195 0.195 0.451 0.293 0.707 0.293s0.512-0.098 0.707-0.293c0.391-0.391 0.391-1.023 0-1.414l-3.707-3.707-3.707 3.707c-0.391 0.391-0.391 1.023 0 1.414s1.023 0.391 1.414 0l1.293-1.293v4.586h-4.586l1.293-1.293c0.391-0.391 0.391-1.023 0-1.414s-1.023-0.391-1.414 0l-3.707 3.707 3.707 3.707c0.195 0.195 0.451 0.293 0.707 0.293s0.512-0.098 0.707-0.293c0.391-0.391 0.391-1.023 0-1.414l-1.293-1.293h4.586v4.586l-1.293-1.293c-0.391-0.391-1.023-0.391-1.414 0s-0.391 1.023 0 1.414l3.707 3.707 3.707-3.707c0.391-0.391 0.391-1.023 0-1.414s-1.023-0.391-1.414 0l-1.293 1.293v-4.586h4.586l-1.293 1.293c-0.391 0.391-0.391 1.023 0 1.414 0.195 0.195 0.451 0.293 0.707 0.293s0.512-0.098 0.707-0.293l3.707-3.707-3.707-3.707z"
    ></path>
  </svg>
</template>
