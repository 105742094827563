import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import streamSaver from 'streamsaver'

streamSaver.mitm = '/mitm.html'

const app = createApp(App)

app.use(createPinia())
app.use(router)

app.mount('#app')
