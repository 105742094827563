<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M14 5v10l-9-5 9-5z"></path>
  </svg>
</template>
