<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      d="M16.998 4.18l-3.154-2.425c-0.872-0.671-2.135-0.506-2.807 0.365l-8.4 10.897c-0.671 0.871-0.507 2.132 0.365 2.803l3.153 2.425c0.872 0.671 2.135 0.506 2.807-0.365l8.401-10.897c0.671-0.871 0.507-2.132-0.365-2.803zM8.548 16.467l-0.537 0.681c-0.152 0.197-0.385 0.31-0.639 0.31-0.124 0-0.309-0.029-0.485-0.164l-3.153-2.425c-0.168-0.129-0.275-0.317-0.303-0.53s0.028-0.422 0.157-0.589l0.537-0.681c0.152-0.197 0.385-0.311 0.64-0.311 0.124 0 0.309 0.029 0.485 0.164l3.154 2.425c0.168 0.129 0.275 0.317 0.303 0.53 0.026 0.213-0.030 0.422-0.159 0.59z"
    ></path>
  </svg>
</template>
