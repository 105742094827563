<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 32 32"
    fill="currentColor"
  >
    <path d="M27 4l-15 15-7-7-5 5 12 12 20-20z"></path>
  </svg>
</template>
